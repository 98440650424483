<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="'Новости/Акции'">
          <div class="text-left">
              <p-button type="info" round @click.native.prevent="createArticle">
                Добавить
              </p-button>
            </div>
          <div class="table-responsive">
            <article-detail v-on:update:table="getArticles"></article-detail>
            <paper-table :data="table.data" :columns="table.columns" :buttons="table.buttons"></paper-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { PaperTable } from "@/components";
import { ArticleDetail } from "@/components";
export default {
  components: {
    PaperTable, ArticleDetail
  },
  created() {
    this.getArticles()
  },
  data() {
    return {
      table: {
        title: "Новости",
        buttons: [
          {
            type: "info",
            text: "Изменить",
            round: true,
            click: this.editArticle,
            param: "id"
          },
          {
            type: "danger",
            text: "Удалить",
            round: true,
            click: this.deleteArticle,
            param: "id"
          }
        ],
        data: [],
        columns: [
          {text: "Название", key: "name"},
          {text: "Дата", key: "date"},
          {text: "Анонс", key: "preview_text"},
          {text: "Фото", key: "photo_url", is_image: true}
        ]
      },
    };
  },
  methods: {
    deleteArticle(id) {
      if (confirm('Удалить?')) {
        this.axios.delete('/domix-article/' + id)
          .then(response => {
            this.getArticles()
          }).catch(error => {
            this.$notify({
              message: error.response.data.message,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
      }
    },
    editArticle(id) {
      this.$modal.show('article-dtl', {article_id: id});
    },
    createArticle() {
      this.$modal.show('article-dtl', {new: true});
    },
    getArticles() {
      this.axios.get('/domix-articles')
      .then(response => {
        this.table.data = []
        response.data.items.forEach((element) => {
          this.table.data.push({
            id: element._id.$oid,
            name: element.name,
            date: new Date(element.date_normal).toLocaleString(),
            preview_text: element.preview_text,
            photo_url: this.axios.defaults.baseURL.replace('/api', '') + element.photo_url,
          })
        })
      });
    }
  }
};
</script>
