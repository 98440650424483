<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card>
          <Teatree :roots="tree_data">
            <template slot="node-toggle" slot-scope="{ node }">
              <NodeToggle :node="node" />
            </template>
            <template slot="node-name" slot-scope="{ node }">
              <img width="20px" height="20px" v-if="node.data.icon"  :src="axios.defaults.baseURL + '/domix-category-icon/' + node.data.uid">
              <NodeName
                :node="node"
                :handleNodeLeftClick="nodeLeftClick"
                :handleNodeRightClick="nodeRightClick"
              />
            </template>
          </Teatree>
          <br>
          <input @change="uploadImage" ref="icon_upload" type="file" accept="image/png" style="display: none">
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Teatree, NodeType, NodeName, NodeToggle } from "vue-teatree";

export default {
  components: {
    Teatree, NodeType, NodeName, NodeToggle
  },
  data() {
    return {
      tree_data: [],
      tree: null,
      imgbase64: null,
      current_uid: null
    };
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.axios.get('/domix-category-tree')
      .then(response => {
        let tree_data = [{
        name: 'Категории',
        data:
          {
            uid: 0,
            icon: false
          },
        show: true,
        showChildren: true,
        selected: false,
        children: [],
      }]
        response.data.forEach((item, i, arr) => {
             tree_data[0]['children'][i] = (this.parseCategory(item));
        })
        this.tree_data = tree_data
      });
    },
    parseCategory(category) {
      let return_data = {
        name: category.name,
        data:
          {
            uid: category.id,
            icon: category.data.icon
          },
        show: true,
        showChildren: true,
        selected: false,
        children: [],
      }

      if (category.child !== undefined) {
        let i;
        for (i = 0; i < category.child.length; i++) {
          return_data.children[i] = this.parseCategory(category.child[i])
        }
      }

      return return_data
    },
    nodeLeftClick(node, event) {
      node.showChildren = !node.showChildren;
    },
    nodeRightClick(node, event) {
      this.current_uid = node.data.uid
      this.$refs.icon_upload.click()
    },
    uploadImage(inp) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.imgbase64 = e.target.result

        this.axios.post('domix-category-icon/' + this.current_uid, {
          "icon": this.imgbase64
        }).then(response => {
          this.getData()
        }).catch((response) => {
          console.log(response)
        });
      }

      reader.readAsDataURL(inp.target.files[0])
      inp.target.value = null;
    }
  }
};
</script>
<style>
.teatree {
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  font-size: 20px;
}

.teatree-node {
  padding-right: 0.25rem;
}

.teatree-node-item {
  display: flex;
  align-items: center;
  height: 2rem;
  background: transparent;

  /* hack to make hover the full width of parent */
  padding-left: 100%;
  margin-left: -100%;
  padding-right: 100%;
  margin-right: -100%;
}

.teatree-node-item:hover {
  background-color: #e0ded6;
}

.teatree-node-item-selected {
  background-color: #e0ded6;
}

.teatree-node-item-name-padded-leaf {
  padding-left: 1.25rem;
}
.teatree-node-item-icon svg {
  height: auto !important;
}
</style>
