<template>
  <modal name="article-dtl" @before-open="beforeOpen" @before-close="beforeClose" :resizable="true" width="60%"
         height="auto">
    <div class="card shadow-none">
      <form @submit.prevent class="card-body">
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text" label="Название" placeholder="Название" v-model="article.name">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="date" label="Дата" placeholder="Дата" v-model="article.date">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <label class="control-label">Анонс</label>
            <textarea rows="10" v-model="article.preview_text" class="form-control"></textarea>
          </div>
          <div class="col-md-4">
            <label class="control-label">Фото</label>
            <div v-if="article.photo">
              <img :src="article.photo" class="rounded mx-auto d-block" style="max-height: 300px">
            </div>
            <input type="file" @change="uploadImage" accept="image/*" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label class="control-label">Текст</label>
            <wysiwyg v-model="article.text"/>
          </div>
        </div>

        <div class="text-center">
          <p-button type="info" round @click.native.prevent="updateArticle">
            Сохранить
          </p-button>
        </div>
        <br>
      </form>
    </div>
  </modal>
</template>
<script>
  export default {
    name: 'article-detail',
    data() {
      return {
        article: {
          id: null,
          date: null,
          name: null,
          preview_text: null,
          text: null,
          photo: null,
        }
      };
    },
    methods: {
      uploadImage(inp) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.imgbase64 = e.target.result
          this.article.photo = this.imgbase64
        }

        reader.readAsDataURL(inp.target.files[0])
        inp.target.value = null;
      },
      updateArticle() {
        if (this.article.id == null) {
          this.axios.post('/domix-article', this.article).then(response => {
            this.$notify({
              message: response.data.message,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'info'
            });
            this.$modal.hide('article-dtl')
          }).catch(error => {
            this.$notify({
              message: error.response.data.message,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        } else {
          this.axios.put('/domix-article', this.article).then(response => {
            this.$notify({
              message: response.data.message,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'info'
            });
            this.$modal.hide('article-dtl')
          }).catch(error => {
            this.$notify({
              message: error.response.data.message,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        }
      },
      beforeClose(event) {
        this.$emit('update:table')
      },
      beforeOpen(event) {
        if (event.params.article_id) {
          this.axios.get('/domix-article/' + event.params.article_id)
            .then(response => {
              let dt = new Date(response.data.date_normal),
                month = '' + (dt.getMonth() + 1),
                day = '' + dt.getDate(),
                year = dt.getFullYear();
              this.article.id = response.data._id["$oid"];
              this.article.date = [year, month, day].join('-');
              this.article.name = response.data.name;
              this.article.preview_text = response.data.preview_text;
              this.article.text = response.data.text;
              this.article.photo = this.axios.defaults.baseURL.replace('/api', '') + response.data.photo_url;
            });
        } else {
          this.article = {
            id: null,
            date: null,
            name: null,
            preview_text: null,
            text: null,
            photo: null,
          }
        }
      },
    },
  };
</script>
<style>
  @import "../../../node_modules/vue-wysiwyg/dist/vueWysiwyg.css";

  .editr--toolbar a svg {
    height: 16px !important;
  }
</style>
