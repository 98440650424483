<template>
  <table class="table" :class="tableClass">
    <thead>
    <slot name="columns">
      <th v-for="column in columns" :key="column.key">{{column.text}}</th>
      <th v-if="buttons"></th>
    </slot>
    </thead>
    <tbody>
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns"
            :key="index">

          <div v-if="column.is_image">
            <img :src="itemValue(item, column)" class="rounded" style="max-height: 100px">
          </div>
          <div v-else>
            {{itemValue(item, column)}}
          </div>
        </td>
        <td v-if="buttons">
          <div v-for="(btn, index) in buttons" :key="`edit-btn-${index}`">
            <p-button :type="btn.type" :round="btn.round" @click.native.prevent="btn.click(item[btn.param])">
              {{ btn.text }}
            </p-button>
          </div>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'paper-table',
  props: {
    buttons: Array,
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.key.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.key.toLowerCase()];
    }
  }
};
</script>
<style>
</style>
