<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" name="Панель управления" icon="ti-panel"/>
        <sidebar-link to="/users" name="Пользователи" icon="ti-user"/>
        <sidebar-link to="/remains" name="Остатки 1С" icon="ti-shopping-cart-full"/>
        <sidebar-link to="/category" name="Категории Домикс" icon="ti-view-list"/>
        <sidebar-link to="/articles" name="Новости/Акции" icon="ti-write"/>
        <sidebar-link to="/orders" name="Заказы" icon="ti-bag"/>
        <sidebar-link to="/icons" name="Логи" icon="ti-align-justify"/>
      </template>
      <mobile-menu></mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
