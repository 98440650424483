import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_API_URL !== undefined ? process.env.VUE_APP_API_URL : 'http://localhost:5000/api'

axios.interceptors.request.use((config) => {
  if (store.getters.currentUser) {
    config.headers['Authorization'] = 'Bearer ' + store.getters.token
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (401 === error.response.status || error.response.status === 422) {
        store.dispatch('logout').then(() => {
          return this.$router.push('login')
        })
      }
    }
    return Promise.reject(error)
  })

Vue.use(VueAxios, axios)
