import Vue from 'vue'
import Vuex from 'vuex'
import jwtDecode from 'jwt-decode'

Vue.use(Vuex)

const state = {
  token: localStorage.getItem('user_token'),
  login: localStorage.getItem('user_login'),
  error: null
}

const getters = {
  token: state => {
    return state.token
  },

  loginError: state => {
    return state.error
  },

  currentUser: state => {
    const decodedToken = state.token && jwtDecode(state.token)
    if (decodedToken) {
      return decodedToken
    } else {
      return null
    }
  }
}

const mutations = {
  LOGIN_SUCCESS (state, token) {
    state.token = token
    state.error = null
  },

  LOGIN_FAILURE (state, error) {
    state.token = null
    state.error = error
  },

  LOGOUT (state) {
    state.token = null
    state.error = null
  }
}

const actions = {
  login (context, auth) {
    return Vue.axios.post('/auth/login', auth)
      .then(response => {
        localStorage.setItem('user_login', auth.login)
        localStorage.setItem('user_token', response.data.token)
        context.commit('LOGIN_SUCCESS', response.data.token)
      }).catch((error) => {
        context.commit('LOGIN_FAILURE', error)
        throw error
      })
  },

  logout (context) {
    localStorage.removeItem('user_token')
    context.commit('LOGOUT')
  }
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  actions
})
