import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import Login from "@/pages/Login.vue";

// Admin pages
import Dashboard from "@/pages/Dashboard.vue";
import Users from "@/pages/Users.vue";
import Remains from "@/pages/Remains.vue";
import Category from "@/pages/Category.vue";
import Articles from "@/pages/Articles.vue";
import Orders from "@/pages/Orders.vue";
import Notifications from "@/pages/Notifications.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Typography from "@/pages/Typography.vue";
import TableList from "@/pages/TableList.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Панель управления",
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "users",
        name: "Пользователи",
        component: Users,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "remains",
        name: "Остатки 1С",
        component: Remains,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "category",
        name: "Категории Домикс",
        component: Category,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "articles",
        name: "Новости/Акции",
        component: Articles,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "orders",
        name: "Заказы",
        component: Orders,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "icons",
        name: "icons",
        component: Icons,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "maps",
        name: "maps",
        component: Maps,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "typography",
        name: "typography",
        component: Typography,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList,
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  { path: "*", component: NotFound }
];

export default routes;
