<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page ">
      <div class="full-page section-image" filter-color="black">
        <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form class="form" @submit.prevent>
                <div class="card card-login">
                  <div class="card-header ">
                    <div class="card-header ">
                      <h3 class="header text-center">Авторизация</h3>
                    </div>
                  </div>
                  <div class="card-body ">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="nc-icon nc-single-02"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" label="Логин" placeholder="Логин" v-model="user.login">
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="nc-icon nc-key-25"></i>
                        </span>
                      </div>
                      <input type="password" class="form-control" label="Пароль" placeholder="Пароль" v-model="user.password">
                    </div>
                  </div>
                  <div class="card-footer ">
                    <button @click="updateProfile" class="btn btn-warning btn-round btn-block mb-3">Авторизация</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="full-page-background" :style="{ backgroundImage: `url('${img}')` }"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/img/bg/fabio-mangione.jpg"),
      user: {
        login: "",
        password: ""
      }
    };
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('login', {
        login: this.user.login,
        password: this.user.password,
      }).then(() => {
        this.notifyVue('top', 'center', 'info', "Авторизации прошла успешно!");
        this.$router.push('dashboard')
      }).catch(() => {
        this.notifyVue('top', 'center', 'danger', "Ошибка авторизации");
      })
    },
    notifyVue(verticalAlign, horizontalAlign, color, message) {
      this.$notify({
        message: message,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color
      });
    }
  }
};
</script>
