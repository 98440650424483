<template>
  <modal name="user-dtl" @before-open="beforeOpen" @before-close="beforeClose" :adaptive="true" :minHeight='400'>
    <card title="Профиль">
      <div>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text" label="Логин" placeholder="Логин" v-model="user.login">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="password" label="Пароль" placeholder="Пароль" v-model="user.password">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <fg-check type="checkbox" label="Активен" placeholder="Активен" v-model="user.active">
              </fg-check>
            </div>
            <div class="col-md-6">
              <fg-check type="checkbox" label="Админ?" placeholder="Админ?" v-model="user.is_admin">
              </fg-check>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <fg-input type="text" label="Логин 1C" placeholder="Логин 1C" v-model="user.one_c.login">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="text" label="Пароль 1C" placeholder="Пароль 1C" v-model="user.one_c.password">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <fg-input type="text" label="ExpoToken" placeholder="ExpoToken" v-model="user.expo_token">
              </fg-input>
            </div>
          </div>

          <div class="text-center">
            <p-button type="info" round @click.native.prevent="updateProfile">
              Сохранить
            </p-button>
          </div>
          <br>
        </form>
      </div>
    </card>
  </modal>
</template>
<script>
export default {
  name: 'user-detail',
  data() {
    return {
      user: {
        id: null,
        login: null,
        password: null,
        expo_token: null,
        active: true,
        is_admin: null,
        one_c: {
          login: null,
          password: null,
        },
      }
    };
  },
  methods: {
    updateProfile() {
      this.axios.post('/user', this.user).then(response => {
          this.$notify({
            message: response.data.message,
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'info'
          });
          this.$modal.hide('user-dtl')
      }).catch(error => {
        this.$notify({
            message: error.response.data.message,
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
      });
    },
    beforeClose(event) {
      this.$emit('update:table')
    },
    beforeOpen(event) {
      if (event.params.user_id) {
        this.axios.get('/user/' + event.params.user_id)
          .then(response => {
            this.user.id = response.data._id["$oid"];
            this.user.login = response.data.login;
            this.user.password = null;
            this.user.active = response.data.active;
            this.user.expo_token = response.data.expo_token;
            this.user.is_admin = response.data.is_admin;
            if (response.data.one_c !== undefined) {
              this.user.one_c = response.data.one_c;
            } else {
              this.user.one_c = {
                login: null,
                password: null,
              }
            }
          });
      } else {
        this.user = {
          id: null,
          login: null,
          password: null,
          expo_token: null,
          active: true,
          is_admin: null,
          one_c: {
            login: null,
            password: null,
          },
        }
      }
    },
  },
};
</script>
