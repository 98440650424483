<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="'Остатки'">
            <div class="row d-flex align-items-center">
              <div class="col-8">
                <fg-input type="text" placeholder="<Код номенклатуры>,<Код характеристики если есть>" v-model="code"></fg-input>
              </div>
              <div class="col-4">
                <p-button type="info" :loading="loading" block @click.native.prevent="getData">
                  Получить остатки
                </p-button>
              </div>
            </div>
          <div class="table-responsive">
            <pre>{{data}}</pre>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      code: null,
      data: null,
      loading: false
    };
  },
  methods: {
    getData() {
      this.loading = true
      this.axios.get('/domix-one-c/' + this.code)
      .then(response => {
        this.data = response.data
        this.loading = false
      });
    }
  }
};
</script>
<style>
  .form-group {
    margin-bottom: 0 !important;
  }
</style>
