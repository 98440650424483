<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <div class="col-md-6 col-xl-3">
        <stats-card>
          <div class="icon-big text-center icon-warning" slot="header">
            <i class="nc-icon nc-bag-16 text-warning"></i>
          </div>
          <div class="numbers" slot="content">
            <p class="card-category">Всего товаров в Домикс</p>
            <p class="card-title">{{domix_count}}</p>
          </div>
        </stats-card>
      </div>


      <div class="col-md-6 col-xl-3">
        <stats-card>
          <div class="icon-big text-center icon-warning" slot="header">
            <i class="nc-icon nc-basket text-success"></i>
          </div>
          <div class="numbers" slot="content">
            <p class="card-category">Всего заказов в Домикс</p>
            <p class="card-title">{{domix_buy_count}}</p>
          </div>
        </stats-card>
      </div>

    </div>

    <div class="row" v-show="paginate">
      <div class="col-12">
        <card :title="log_table.title">
          <div slot="raw-content" class="table-responsive">
            <paper-table :data="log_table.data" :columns="log_table.columns"></paper-table>
            <paginate
              :page-count="paginate.pages"
              :click-handler="getLog"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :next-link-class="'page-link'"
              :prev-link-class="'page-link'"
            >
            </paginate>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import { StatsCard } from "@/components/index";
import moment from 'moment'
import { PaperTable } from "@/components";
import Paginate from 'vuejs-paginate'
export default {
  components: {
    StatsCard,
    PaperTable,
    Paginate
  },
  created() {
    this.getLog();
    this.axios.get('/stats')
    .then(response => {
      this.domix_count = response.data.domix_products;
      this.domix_buy_count = response.data.domix_buy_count;
    });
  },
  data() {
    return {
      domix_count: 0,
      domix_buy_count: 0,
      paginate: false,
      log_table: {
        title: "Логи импорта",
        data: [],
        columns: [
          {text: "ID", key: "id"},
          {text: "Дата", key: "date"},
          {text: "Кол-во ошибок", key: "error"}
        ]
      },
    };
  },
  methods: {
    getLog(page = 1) {
      this.axios.get('/log?page='+page)
      .then(response => {
        this.log_table.data = []
        this.paginate = response.data.pagination
        response.data.items.forEach((element) => {
          this.log_table.data.push({
            id: element._id.$oid,
            date: moment(element.date.$date).format('YYYY.MM.DD hh:mm'),
            error: element.errors,
          })
        })
      });
    }
  }
};
</script>
<style>
</style>
