import Vue from "vue";
import App from "./App";
import router from "./router/index";
import VModal from 'vue-js-modal'

import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: { "image": true },
});

import store from '@/store'
import '@/api'

Vue.use(PaperDashboard);

Vue.use(VModal);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.currentUser) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
