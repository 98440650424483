<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="table.title">
          <div slot="raw-content" class="table-responsive" v-show="paginate">
            <div class="text-left">
              <p-button type="info" round @click.native.prevent="createUser">
                Добавить пользователя
              </p-button>
            </div>
            <user-detail v-on:update:table="getUsers"></user-detail>
            <paper-table :data="table.data" :columns="table.columns" :buttons="table.buttons"></paper-table>
            <paginate
              :page-count="paginate.pages"
              :click-handler="getUsers"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :next-link-class="'page-link'"
              :prev-link-class="'page-link'"
            >
            </paginate>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { PaperTable } from "@/components";
import { UserDetail } from "@/components";
import Paginate from 'vuejs-paginate'

export default {
  components: {
    PaperTable, UserDetail, Paginate
  },
  created() {
    this.getUsers()
  },
  data() {
    return {
      paginate: false,
      table: {
        title: "Пользователи",
        buttons: [
          {
            type: "info",
            text: "Изменить",
            round: true,
            click: this.editUser,
            param: "id"
          }
        ],
        data: [],
        columns: [
          {text: "ID", key: "id"},
          {text: "Логин", key: "login"},
          {text: "Активен", key: "active"},
          {text: "Админ", key: "is_admin"},
          {text: "Пользователь 1С", key: "one_c_user"},
        ]
      },
    };
  },
  methods: {
    createUser() {
      this.$modal.show('user-dtl', {new: true});
    },
    editUser(id) {
      this.$modal.show('user-dtl', {user_id: id});
    },
    getUsers(page = 1) {
      this.axios.get('/users?page='+page)
      .then(response => {
        this.table.data = []
        this.paginate = response.data.pagination
        response.data.items.forEach((element) => {
          this.table.data.push({
            id: element._id.$oid,
            login: element.login,
            active: element.active ? 'Да' : 'Нет',
            is_admin: element.is_admin ? 'Да' : 'Нет',
            one_c_user: element.one_c ? element.one_c.login : '---',
          })
        })
      });
    }
  }
};
</script>
<style>
</style>
