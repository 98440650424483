<template>
  <div>
    <modal name="response" :adaptive="true" :scrollable="true" :height="'auto'">
      <textarea rows="30" cols="30" style="width: 100%">{{responseData}}</textarea>
    </modal>
    <div class="row" v-show="paginate">
      <div class="col-12">
        <card :title="'Заказы'">
          <div class="table-responsive">
            <paper-table :data="table.data" :columns="table.columns" :buttons="table.buttons"></paper-table>
            <paginate
              :page-count="paginate.pages"
              :click-handler="getOrders"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :next-link-class="'page-link'"
              :prev-link-class="'page-link'"
            >
            </paginate>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { PaperTable } from "@/components";
import Paginate from 'vuejs-paginate'
export default {
  components: {
    PaperTable, Paginate
  },
  created() {
    this.getOrders()
  },
  data() {
    return {
      paginate: false,
      responseData: null,
      table: {
        title: "Заказы",
        buttons: [
          {
            type: "info",
            text: "Просмотр запроса в 1С",
            round: true,
            click: this.checkOrder,
            param: "id"
          },
          {
            type: "danger",
            text: "Отправить повторно в 1С",
            round: true,
            click: this.sendOrder,
            param: "id"
          },
        ],
        data: [],
        columns: [
          {text: "Дата", key: "date"},
          {text: "Сумма", key: "sum"},
          {text: "Тип", key: "type"},
          {text: "Номер заказа", key: "number_1c"}
        ]
      },
    };
  },
  methods: {
    sendOrder(id) {
      if (confirm('Уверены?')) {
        this.axios.get('/domix-order-send/' + id)
          .then(response => {
            this.responseData = response.data
            this.$modal.show('response');
          }).catch(error => {
            this.$notify({
              message: error.response.data.message,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
      }
    },
    checkOrder(id) {
      if (confirm('Уверены?')) {
        this.axios.get('/domix-order-send-check/' + id)
          .then(response => {
            this.responseData = response.data
            this.$modal.show('response');
          }).catch(error => {
            this.$notify({
              message: error.response.data.message,
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
      }
    },
    getOrders(page = 1) {
      this.axios.get('/domix-orders?page='+page)
      .then(response => {
        this.table.data = []
        this.paginate = response.data.pagination
        response.data.items.forEach((element) => {
          this.table.data.push({
            id: element._id.$oid,
            number_1c: element.number_1c,
            type: (element.type === 'order') ? 'Заказ' : 'Чек',
            date: new Date(element.date_normal).toLocaleString(),
            sum: element.sum.toFixed(2)
          })
        })
      });
    }
  }
};
</script>
